import React from 'react'
import { faBan, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColorKey } from '../../models/theme'
import toValidHTMLHexColor from '@/common/services/to-valid-html-hex-color'

export type ColorValueInputProps = {
  colorKey: ColorKey
  colorValue: string | null
  onChange: (value: string | null) => void
  placeholder: string | undefined
  defaultIconColor?: string
  label?: string
  className?: string
}

const ColorValueInput = ({
  colorKey,
  colorValue,
  onChange,
  placeholder,
  defaultIconColor = 'cccccc',
  label,
  className,
}: ColorValueInputProps): JSX.Element => {
  const hexColor = toValidHTMLHexColor(colorValue)

  return (
    <div className={`${className}`}>
      {label && <label htmlFor="color.primary">{label}</label>}
      <div className="flex flex-row gap-2 items-center">
        <FontAwesomeIcon
          icon={hexColor ? faStop : faBan}
          className={hexColor ? 'shadow-md' : undefined}
          fixedWidth={true}
          color={`#${hexColor || defaultIconColor}`}
          size="lg"
        />
        <input
          type="text"
          placeholder={placeholder}
          defaultValue={hexColor || undefined}
          id={colorKey.toString()}
          name={colorKey}
          onChange={({ target: { value } }) => onChange(value)}
          className="w-full px-8 border rounded px-3 py-2"
        />
      </div>
    </div>
  )
}
export default ColorValueInput
