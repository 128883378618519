import React from 'react'
import logo from '../../../portal/images/logo.png'
import { ThemeConfig } from '../../../admin/models/theme'
import './logo.css'
import { themeState } from '../../state/atoms/theme-state'
import { useRecoilState } from 'recoil'
import { Config } from '../../../config'

type Props = {
  className?: string
}
const Logo = ({ className }: Props) => {
  const [theme] = useRecoilState<ThemeConfig>(themeState)

  return (
    <img
      data-test-id="Logo"
      className={`logo-image ${className}`}
      src={theme.logo ? `${Config.ThemeImageDir}/${theme.logo}` : logo}
    />
  )
}

export default React.memo(Logo)
