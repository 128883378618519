import React from 'react'
import './hero-image.scss'

type Props = {
  backgroundSrc: string
}
const HeroImage = (props: Props): JSX.Element => (
  <div
    style={{ backgroundImage: `url(${props.backgroundSrc})` }}
    className="heroImage"
  ></div>
)

export default HeroImage
