import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faTachometerAlt,
  faTools,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, Navigate } from 'react-router-dom'
import './navigation.scss'
import Logo from '@/common/components/logo'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { useMutationObserver } from 'rooks'
import { loginRequest, signupRequest } from '@/portal/msal-config'
import { useRecoilState } from 'recoil'
import { userState } from '@/common/state/atoms/user-state'

const AdminNavigation: React.FC = () => {
  const { t } = useTranslation()
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const { instance } = useMsal()
  const [paddingRight, setPaddingRight] = useState(0)

  const doLogout = () => {
    instance.logout(loginRequest)
    instance.logout(signupRequest)
  }

  const documentRef = useRef(document.documentElement)

  useMutationObserver(documentRef, () => {
    const scrollBarPadding = documentRef.current.style.paddingRight
    setPaddingRight(parseInt(scrollBarPadding || '0'))
  })

  return isLoggedIn ? (
    <nav
      id="header"
      style={{ paddingRight: paddingRight }}
      className="bg-white fixed w-full z-10 top-0 shadow"
    >
      <div className="w-full container pl-4 mx-auto flex flex-wrap items-center justify-between my-4">
        <div className="pl-4 md:pl-0">
          <Link to="/admin">
            <Logo className="logo-image-2x" />
          </Link>
        </div>
        <div className="pr-0 flex justify-end">
          <NavLink
            to="/admin/dashboard"
            className={(props) => {
              return `${props.isActive ? 'active ' : ''}mr-6 mt-2 navLink`
            }}
          >
            <>
              <FontAwesomeIcon
                size="lg"
                className="mr-2"
                icon={faTachometerAlt}
              />
              {t('dashboard')}
            </>
          </NavLink>
          <NavLink
            to="/admin/users"
            className={(props) => {
              return `${props.isActive ? 'active ' : ''}mr-6 mt-2 navLink`
            }}
          >
            <>
              <span className="relative mr-2">
                <FontAwesomeIcon size="lg" icon={faUser} />
              </span>
              {t('users')}
            </>
          </NavLink>
          <NavLink
            to="/admin/configuration"
            className={(props) => {
              return `${props.isActive ? 'active ' : ''}mr-6 mt-2 navLink`
            }}
          >
            <>
              <FontAwesomeIcon size="lg" className="mr-2" icon={faTools} />
              {t('configuration')}
            </>
          </NavLink>
          <button
            onClick={doLogout}
            className="mr-6 mt-2 text-base font-weight-500 font-normal outline-none focus:outline-none"
          >
            <FontAwesomeIcon size="lg" className="mr-2" icon={faSignOutAlt} />
            {t('log_out')}
          </button>
        </div>
      </div>
    </nav>
  ) : (
    <Navigate to="/" />
  )
}

export default AdminNavigation
