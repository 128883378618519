import { Dialog } from '@headlessui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  text?: string
  children?: React.ReactNode
  showButtons: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ModalDialog = ({
  isOpen,
  title,
  children,
  text,
  setIsOpen,
  showButtons = true,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="modal fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white border-t-8 border-primary rounded-lg px-4 py-2 flex flex-col">
          <Dialog.Title className="block text-lg font-bold">
            {title}
          </Dialog.Title>
          <div>
            <>{children ?? text ?? ''}</>
          </div>
        </div>
        {showButtons && (
          <div className="p-4 flex space-x-4">
            <button
              className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </button>
            <button
              className="w-1/2 px-4 py-3 text-center text-pink-100 bg-primary rounded-lg hover:bg-secondary hover:text-white font-bold text-sm"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              {t('save')}
            </button>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default ModalDialog
