import React, { useState, MouseEvent } from 'react'
import { useTable } from 'react-table'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalDialog from '../../components/modal-dialog'
import User from '../../components/user'
import { User as UserModel } from '@/core/models/user'
import { useTranslation } from 'react-i18next'

const Users = (): JSX.Element => {
  const [isUserDialogVisible, setIsUserDialogVisible] = useState<boolean>(false)
  const [userEditRecord, setUserEditRecord] = useState<UserModel | undefined>()
  const { t } = useTranslation()

  const addUser = useMutation(
    (user: UserModel) => axios.post('/register', user),
    {
      onSuccess: () => {
        setIsUserDialogVisible(false)
        return refetchUsers()
      },
    },
  )

  const updateUser = useMutation(
    (user: UserModel) => axios.put(`/users/${user.id}`, user),
    {
      onSuccess: () => {
        setIsUserDialogVisible(false)
        setUserEditRecord(undefined)
        return refetchUsers()
      },
    },
  )

  const deleteUser = useMutation((id: number) => axios.delete(`/users/${id}`), {
    onSuccess: () => {
      return refetchUsers()
    },
  })

  const onSubmitUser = (user: UserModel) => {
    if (user.id > 0) {
      updateUser.mutate(user)
    } else {
      addUser.mutate(user)
    }
  }

  const { data, refetch: refetchUsers } = useQuery('fetchUsers', () =>
    axios('users'),
  )

  const users = React.useMemo(() => data?.data ?? [], [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'status',
        accessor: 'status',
      },
      {
        Header: 'role',
        accessor: 'role',
      },
    ],
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: users.map((u: UserModel) => ({
        ...u,
        status: u.isActive ? 'active' : 'inactive',
      })),
    })

  return (
    <>
      <div className="flex flex-col w-full">
        <h1 className="text-xl pb-6">Admin users</h1>
        <div className="w-full">
          <div className="bg-white shadow-md rounded my-6">
            <table {...getTableProps()} className="min-w-max w-full table-auto">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="py-3 px-6 text-left"
                      >
                        {t(`${column.render('Header')}`)}
                      </th>
                    ))}
                    <th className="flex flex-row justify-end px-4"></th>
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="text-gray-600 text-sm font-light"
              >
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-center">
                        <div className="flex items-center">
                          <span>{row.cells[0].render('Cell')}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <div className="flex items-center">
                          <span>{row.cells[1].render('Cell')}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <span
                          className={`${
                            users[row.index]?.isActive
                              ? 'bg-green-200'
                              : 'bg-red-200'
                          } text-grey-600 py-1 px-3 rounded-full text-xs`}
                        >
                          {row.cells[2].render('Cell')}
                        </span>
                      </td>
                      <td className="py-3 px-6 text-left">
                        {row.cells[3].render('Cell')}
                      </td>
                      <td className="py-3 px-6 text-right">
                        <div className="flex flex-row justify-end">
                          <div className="w-4 mr-4 transform hover:text-primary hover:scale-110">
                            <span
                              onClick={() =>
                                setUserEditRecord(users[row.index])
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} size="lg" />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ModalDialog
          title={userEditRecord ? t('edit_user') : t('add_user')}
          showButtons={false}
          isOpen={isUserDialogVisible || !!userEditRecord}
          setIsOpen={() => {
            setIsUserDialogVisible(false)
            setUserEditRecord(undefined)
          }}
        >
          <User
            onSubmit={onSubmitUser}
            onCancel={(event: MouseEvent) => {
              event.preventDefault()
              setIsUserDialogVisible(false)
              setUserEditRecord(undefined)
            }}
            user={userEditRecord}
          />
        </ModalDialog>
      </div>
    </>
  )
}

export default Users
