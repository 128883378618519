import React, { MouseEvent, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { User as UserModel, UserRole } from '@/core/models/user'

interface Props {
  user?: UserModel
  onSubmit?: (user: UserModel) => void
  onCancel: (evt: MouseEvent) => void
}

const User = ({ user, onSubmit, onCancel }: Props): JSX.Element => {
  const { t } = useTranslation()
  const localUser = user ?? {
    id: 0,
    role: 'admin',
    email: '',
    isActive: true,
    fullName: '',
    language: 'en',
  }

  const onSave = (event: FormEvent) => {
    event.preventDefault()
    const data = new FormData(event.target as HTMLFormElement)

    //TODO: parse with zod
    const role = data.get('role') as UserRole
    const isActive = data.get('isActive') === 'on'

    if (onSubmit && role !== null && typeof role === 'string') {
      onSubmit({ ...localUser, role, isActive })
    }
  }

  return (
    <form className="px-8 pt-6" onSubmit={onSave} autoComplete="off">
      <input type="hidden" autoComplete="off" />
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="email"
        >
          {t('email') + ' *'}
        </label>
        {localUser.email}
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="active"
        >
          {t('active')}
        </label>
        <input
          id="active"
          type="checkbox"
          name="isActive"
          value="on"
          defaultChecked={localUser.isActive}
          placeholder="Username"
        />
      </div>
      <div className="mb-6">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="role"
        >
          {t('role') + ' *'}
        </label>
        <select
          defaultValue={localUser.role}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="role"
          name="role"
        >
          <option value="superadmin">Admin</option>
          <option value="admin">Fund manager</option>
          <option value="client">Customer</option>
        </select>
      </div>
      <div className="flex flex-row gap-6 py-3">
        <button
          className="w-1/2 px-4 py-3 flex-grow text-center bg-gray-100 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
          onClick={onCancel}
        >
          {t('cancel')}
        </button>
        <button className="w-1/2 px-4 py-3 flex-grow text-center text-white bg-primary rounded-lg hover:bg-secondary hover:text-white font-bold text-sm">
          {t('save')}
        </button>
      </div>
    </form>
  )
}
export default User
