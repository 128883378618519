import { atom } from 'recoil'
import { CreateInvestmentLevelDto } from '../../../../core/src/models/create-investment-level.dto'

export const investmentLevelState = atom<CreateInvestmentLevelDto>({
  key: 'riskLevelBoundaries',
  default: {
    neutralBottom: 0,
    neutralTop: 0,
  },
})
