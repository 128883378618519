import React from 'react'

type Props = {
  className?: string
}
const Spinner: React.FunctionComponent<Props> = ({ className = '' }: Props) => (
  <div className=" flex justify-center items-center">
    <div
      className={`animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 ${className}`}
    />
  </div>
)

export default Spinner
