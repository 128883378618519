import React from 'react'
import HeroImage from './hero-image'
import img from '../../images/port.png'
import AccountDetails from './account-details'
import ReportSummary from './report-summary'
import { useRecoilState } from 'recoil'
import { themeState } from '@/common/state/atoms/theme-state'
import { Config } from '../../../config'

const Dashboard: React.FunctionComponent = () => {
  const [theme] = useRecoilState(themeState)
  return (
    <div className="w-full" data-test-id="ClientDashboard">
      <HeroImage
        backgroundSrc={
          theme.coverImage ? `${Config.ThemeImageDir}/${theme.coverImage}` : img
        }
      />
      <div className="w-full flex flex-col md:flex-row gap-6">
        <AccountDetails className="w-full md:w-1/2 my-6" />
        <ReportSummary className="w-full md:w-1/2 my-6" />
      </div>
    </div>
  )
}

export default Dashboard
