const toValidHexColor = (colorString: string | File | null): string | null => {
  if (typeof colorString !== 'string') {
    return null
  }

  const matches = colorString.match(/^#?(?:[0-9a-fA-F]{3}){1,2};?$/)
  if (!matches || matches.length === 0) return null

  return matches[0].replace('#', '').replace(';', '')
}

export default toValidHexColor
