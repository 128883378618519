import { atom } from 'recoil'
import { ThemeConfig } from '../../../admin/models/theme'

export const themeState = atom<ThemeConfig>({
  key: 'appTheme',
  default: {
    name: '',
    primaryColor: null,
    secondaryColor: null,
    backgroundColor: null,
    textColor: null,
    logo: null,
    backgroundImage: null,
    coverImage: null,
  },
})
