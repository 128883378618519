import React from 'react'
import generatedData from '../../../common/services/data.json'
import generatedFundData from '../../../common/services/fund-manager-data.json'
import { UserRole } from '@/core/models/user'
import { useRecoilState } from 'recoil'
import { userState } from '@/common/state/atoms/user-state'

export type ReportSummaryProps = {
  className?: string
}

const ReportSummary = ({ className = '' }: ReportSummaryProps): JSX.Element => {
  const [user] = useRecoilState(userState)
  return (
    <div
      className={`${
        className ?? ''
      } bg-white shadow overflow-hidden sm:rounded-lg `}
    >
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium">
          Laatste rapportoverzicht
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium">Waarde participaties</dt>
            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`€ ${new Intl.NumberFormat(
              'nl-NL',
            ).format(
              user?.role === UserRole.CLIENT
                ? generatedData[0].waardeParticpaties
                : generatedFundData[0].huidigGestortBedrag,
            )}`}</dd>
          </div>
          <div className="bg-white px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium">Maandrendement</dt>
            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`${
              user?.role === UserRole.CLIENT
                ? generatedData[0].maandRendement
                : generatedFundData[0].maandRendement
            }%`}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium">Rendement YTD</dt>
            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`${
              user?.role === UserRole.CLIENT
                ? generatedData[0].rendementYTD
                : generatedFundData[0].rendementYTD
            }%`}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default ReportSummary
