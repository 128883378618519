export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID as string,
    knownAuthorities: [import.meta.env.VITE_MSAL_KNOWN_AUTHORITIES] as string[],
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI as string,
    postLogoutRedirectUri: import.meta.env
      .VITE_MSAL_POST_LOGOUT_REDIRECT_URI as string,
  },
  cache: {
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid'],
  authority: import.meta.env.VITE_MSAL_LOGIN_AUTHORITY as string,
}
export const signupRequest = {
  scopes: ['openid'],
  authority: import.meta.env.VITE_MSAL_SIGNUP_AUTHORITY as string,
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  // graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
}
