import React, { FormEvent, MouseEvent, useRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { useMutation } from 'react-query'
import './theme.scss'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import axios from 'axios'
import { ColorKey, ImageKey, ThemeConfig } from '../../models/theme'
import ColorValueInput from '../../components/color-value-input'
import toValidHTMLHexColor from '@/common/services/to-valid-html-hex-color'
import { themeState } from '@/common/state/atoms/theme-state'
import { investmentLevelState } from '@/common/state/atoms/investment-risk-level-state'
import { useRecoilState } from 'recoil'
import { isEmpty, parseInt } from 'lodash'
import { Config } from '../../../config'
import { CreateInvestmentLevelDto } from '@/core/models/create-investment-level.dto'
import { useTranslation } from 'react-i18next'

registerPlugin(FilePondPluginImagePreview)
const colorPlaceholder = 'e.g. #ffffff, #fff, ffffff, fff'

function isString(value: any): value is string {
  return typeof value === 'string'
}
const Configuration = (): JSX.Element => {
  const { t } = useTranslation()
  const [theme, setTheme] = useRecoilState(themeState)
  const [localTheme, setLocalTheme] = useState<ThemeConfig>(theme)
  const [riskLevels, setRiskLevels] = useRecoilState(investmentLevelState)
  const mutateTheme = useMutation(
    (themeConfig: ThemeConfig) => axios.post('/customization', themeConfig),
    {
      onSuccess: (data) => {
        const theme = data.data
        setTheme(theme)
        setRiskLevels(theme.riskLevelBoundaries)
      },
    },
  )

  const fileUploadConfig = {
    instantUpload: false,
    process: {
      url: `${Config.ApiBaseUrl}/customization/upload`,
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    },
    load: {
      url: `${Config.ThemeImageDir}/`,
    },
    revert: {
      url: `${Config.ApiBaseUrl}/customization/upload`,
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    },
  }
  const coverImageUploaderRef = useRef<FilePond>(null)
  const backgroundImageUploaderRef = useRef<FilePond>(null)
  const logoUploaderRef = useRef<FilePond>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const getThemeConfiguration = (): ThemeConfig & {
    riskLevelBoundaries?: CreateInvestmentLevelDto
  } => {
    const data = new FormData(formRef.current || undefined)
    const name = data.get('name')
    const logo =
      (logoUploaderRef.current?.getFile() || undefined)?.serverId ?? null
    const coverImage =
      (coverImageUploaderRef.current?.getFile() || undefined)?.serverId ?? null
    const backgroundImage =
      (backgroundImageUploaderRef.current?.getFile() || undefined)?.serverId ??
      null

    const riskLevelBoundariesNeutralTop = data.get(
      'riskLevelBoundaries.neutralTop',
    )
    const riskLevelBoundariesNeutralBottom = data.get(
      'riskLevelBoundaries.neutralBottom',
    )

    return {
      logo,
      coverImage,
      backgroundImage,
      name: typeof name === 'string' ? name : '',
      primaryColor: toValidHTMLHexColor(data.get('primaryColor')),
      secondaryColor: toValidHTMLHexColor(data.get('secondaryColor')),
      backgroundColor: toValidHTMLHexColor(data.get('backgroundColor')),
      textColor: toValidHTMLHexColor(data.get('textColor')),
      riskLevelBoundaries: {
        neutralTop: isString(riskLevelBoundariesNeutralTop)
          ? parseInt(riskLevelBoundariesNeutralTop, 10)
          : 0,
        neutralBottom: isString(riskLevelBoundariesNeutralBottom)
          ? parseInt(riskLevelBoundariesNeutralBottom, 10)
          : 0,
      },
    }
  }

  const preview = (event: MouseEvent) => {
    event.preventDefault()
    setTheme(getThemeConfiguration())
  }

  const save = (event: FormEvent) => {
    event.preventDefault()
    const themeSettings = getThemeConfiguration()
    mutateTheme.mutate(themeSettings)
  }

  const changeColor = (key: ColorKey, value: string | null) => {
    setLocalTheme({ ...localTheme, [key]: value ? value : null })
  }

  const changeRiskLevel = (
    key: 'neutralBottom' | 'neutralTop',
    value: string | null,
  ) => {
    const intValue = value ? parseInt(value) : null
    setRiskLevels({ ...riskLevels, [key]: intValue })
  }

  const setImage = (key: ImageKey, value: string | null) => {
    setLocalTheme({ ...localTheme, [key]: value })
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .filepond--wrapper {
                width: 100%
            }`,
        }}
      />
      <form ref={formRef} className="w-full" onSubmit={save}>
        <div className="flex flex-row gap-6">
          <fieldset className="lg:w-2/3 w-1/2">
            <label htmlFor="name">{t('company_name') + ' *'}</label>
            <input
              type="text"
              defaultValue={localTheme?.name}
              name="name"
              required
              className="w-full px-3 border rounded py-2"
            />
            <label>{t('logo')}</label>
            <FilePond
              allowMultiple={false}
              files={
                localTheme.logo
                  ? [
                      {
                        // the server file reference
                        source: localTheme.logo,
                        // set type to limbo to tell FilePond this is a temp file
                        options: {
                          type: 'local',
                        },
                      },
                    ]
                  : undefined
              }
              server={fileUploadConfig}
              onprocessfile={(error, file) => {
                setImage('logo', file.serverId)
              }}
              onupdatefiles={(files) => {
                isEmpty(files) && setImage('logo', null)
              }}
              credits=""
              labelFileProcessingRevertError={''}
              ref={logoUploaderRef}
              labelIdle={`${t(
                'drag_drop_files',
              )} <span class="filepond--label-action"> ${t('browse')} </span>`}
            />

            <label>{t('cover_image')}</label>
            <FilePond
              allowMultiple={false}
              server={fileUploadConfig}
              onprocessfile={(error, file) => {
                setImage('coverImage', file.serverId)
              }}
              onupdatefiles={(files) => {
                isEmpty(files) && setImage('coverImage', null)
              }}
              files={
                localTheme.coverImage
                  ? [
                      {
                        // the server file reference
                        source: localTheme.coverImage,
                        // set type to limbo to tell FilePond this is a temp file
                        options: {
                          type: 'local',
                        },
                      },
                    ]
                  : undefined
              }
              credits=""
              ref={coverImageUploaderRef}
              labelIdle={`${t(
                'drag_drop_files',
              )} <span class="filepond--label-action"> ${t('browse')} </span>`}
            />
            <label>{t('background_image')}</label>
            <FilePond
              allowMultiple={false}
              server={fileUploadConfig}
              onprocessfile={(error, file) => {
                setImage('backgroundImage', file.serverId)
              }}
              onupdatefiles={(files) => {
                isEmpty(files) && setImage('backgroundImage', null)
              }}
              files={
                localTheme.backgroundImage
                  ? [
                      {
                        // the server file reference
                        source: localTheme.backgroundImage,
                        // set type to limbo to tell FilePond this is a temp file
                        options: {
                          type: 'local',
                        },
                      },
                    ]
                  : undefined
              }
              credits=""
              ref={backgroundImageUploaderRef}
              labelIdle={`${t(
                'drag_drop_files',
              )} <span class="filepond--label-action"> ${t('browse')} </span>`}
            />
          </fieldset>
          <fieldset className="flex flex-col flex-grow gap-6">
            <ColorValueInput
              colorKey="primaryColor"
              colorValue={localTheme['primaryColor'] || theme['primaryColor']}
              onChange={(value) => changeColor('primaryColor', value)}
              label={t('primary_color')}
              placeholder={colorPlaceholder}
            />

            <ColorValueInput
              colorKey="secondaryColor"
              colorValue={
                localTheme['secondaryColor'] || theme['secondaryColor']
              }
              onChange={(value) => changeColor('secondaryColor', value)}
              label={t('secondary_color')}
              placeholder={colorPlaceholder}
            />
            <ColorValueInput
              colorKey="backgroundColor"
              colorValue={
                localTheme['backgroundColor'] || theme['backgroundColor']
              }
              onChange={(value) => changeColor('backgroundColor', value)}
              label={t('background_color')}
              placeholder={colorPlaceholder}
            />
            <ColorValueInput
              colorKey="textColor"
              colorValue={localTheme['textColor'] || theme['textColor']}
              onChange={(value) => changeColor('textColor', value)}
              label={t('text_color')}
              placeholder={colorPlaceholder}
            />
          </fieldset>
        </div>
        <div>
          <fieldset className="lg:w-2/3 w-1/2 flex flex-row gap-4">
            <label
              className="w-1/2"
              htmlFor="riskLevelBoundaries.neutralBottom"
            >
              {t('neutral_risk_bottom_value') + ' *'}
              <input
                type="text"
                name="riskLevelBoundaries.neutralBottom"
                required
                className="w-full px-3 border rounded py-2"
                defaultValue={riskLevels.neutralBottom || undefined}
                onChange={({ target: { value } }) =>
                  changeRiskLevel('neutralBottom', value)
                }
              />
            </label>

            <label className="w-1/2" htmlFor="riskLevelBoundaries.neutralTop">
              {t('neutral_risk_top_value') + ' *'}
              <input
                type="text"
                name="riskLevelBoundaries.neutralTop"
                required
                className="w-full px-3 border rounded py-2"
                defaultValue={riskLevels.neutralTop || undefined}
                onChange={({ target: { value } }) =>
                  changeRiskLevel('neutralTop', value)
                }
              />
            </label>
          </fieldset>
        </div>
        <div className="flex flex-row justify-end">
          <button className="btn my-4 mr-6" onClick={preview}>
            {t('preview')}
          </button>
          {/*TODO: enable the clear functionality */}
          {/*<button className="btn my-4 mr-6"*/}
          {/*        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {e.preventDefault(); onClearThemePreview()}}>*/}
          {/*  Clear Preview*/}
          {/*</button>*/}
          <button className="btn btn-primary my-4">
            {t('save_your_changes')}
          </button>
        </div>
      </form>
    </>
  )
}
export default Configuration
