import { Language } from './i18n'

export interface User {
  id: number
  email: string
  fullName: string
  role: UserRole
  isActive: boolean
  language: Language
}

export enum UserRole {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  CLIENT = 'client',
}
